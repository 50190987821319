<template>
  <v-container fluid>
    <main-layout />
    <v-form ref="form" v-model="formValid" lazy-validation>
      <div class="mx-4">
        <v-row justify-sm="center">
          <v-col cols="12" sm="6" md="4" class="pa-0">
            <h2 class="ml-2 mt-8 font-weight-black">
              {{ $t("reset_password.title") }}
            </h2>
            <h4 class="ml-3 mb-8 mt-2 font-weight-light">
              {{ $t("reset_password.new_password_text") }}
            </h4>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center" v-if="!uid">
          <v-col cols="12" sm="6" md="4" class="pa-0">
            <v-text-field
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              :hint="$t('validation.password')"
              :label="$t('field.password')"
              outlined
              v-model="old_password"
            >
              <v-icon slot="append" color="primary" @click="show1 = !show1">{{
                show1 ? "mdi-eye" : "mdi-eye-off"
              }}</v-icon>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="6" md="4" class="pa-0">
            <v-text-field
              :type="show2 ? 'text' : 'password'"
              :hint="$t('validation.password')"
              :label="$t('field.new_password')"
              outlined
              v-model="new_password1"
            >
              <v-icon slot="append" color="primary" @click="show2 = !show2">{{
                show2 ? "mdi-eye" : "mdi-eye-off"
              }}</v-icon>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="6" md="4" class="pa-0">
            <v-text-field
              :type="show3 ? 'text' : 'password'"
              :hint="$t('validation.password')"
              :label="$t('field.repeat_new_password')"
              outlined
              validate-on-blur
              :rules="samePassword"
              v-model="new_password2"
            >
              <v-icon slot="append" color="primary" @click="show3 = !show3">{{
                show3 ? "mdi-eye" : "mdi-eye-off"
              }}</v-icon>
            </v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-form>
    <v-row class="mx-4 my-8" justify="center" justify-md="center">
      <v-col cols="12" sm="4" md="2" class="pa-0 text-right">
        <div>
          <v-btn
            color="secondary"
            block
            large
            rounded
            :disabled="!formValid"
            @click="reset()"
            class="text-capitalize text-h6 font-weight-light"
          >
            {{ $t("reset_password.title") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-footer :absolute="true" color="transparent" class="primary--text">
      <v-row class="mb-10">
        <v-col class="text-center">
          <span class="text-subtitle-1"
            >{{ $t("reset_password.remember_password") }}
            <a class="font-weight-bold" @click="redirectLogin">{{
              $t("reset_password.access")
            }}</a></span
          >
        </v-col>
      </v-row>
    </v-footer>
  </v-container>
</template>
<script>
import MainLayout from "@/components/layouts/Main.vue";
import AuthService from "@/services/AuthService.js";
export default {
  components: {
    MainLayout
  },
  data() {
    return {
      old_password: "",
      new_password1: "",
      new_password2: "",
      uid: "",
      token: "",
      csrfmiddlewaretoken: "",
      show1: false,
      show2: false,
      show3: false,
      formValid: true,
      samePassword: [
        v => v == this.new_password1 || this.$i18n.t("validation.same_password")
      ]
    };
  },
  created() {
    this.uid = this.$route.query.uid;
    this.token = this.$route.query.token;
  },
  mounted() {
    const username = this.$route.query.username;
    const password = this.$route.query.password;

    if (username && password) {
      this.old_password = password;
      this.login(username, password);
    }
  },
  methods: {
    redirectLogin() {
      this.$router.push({ name: "login" });
    },
    login(username, password) {
      this.$store
        .dispatch("user/login", {
          username: username,
          password: password
        })
        .then(() => {
          this.$notify({
            title: "Login",
            text: this.$i18n.t("login.logged_in"),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success"
          });
        })
        .catch(error => {
          this.$notify({
            title: "Error",
            text: error.response.data.detail,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        });
    },
    reset() {
      // if (this.uid){
      //   this.sendConfirm()
      //   return
      // }
      if (!this.$refs.form.validate()) {
        this.showConfirmPay = true;
      } else {
        let res;
        if (!this.uid) {
          res = AuthService.passwordChange({
            old_password: this.old_password,
            new_password1: this.new_password1,
            new_password2: this.new_password2
          });
        } else {
          res = this.sendConfirm();
        }
        res
          .then(() => {
            this.$notify({
              title: this.$i18n.tc("reset_password.password_save", 1),
              text: this.$i18n.tc("reset_password.password_save", 2),
              iconPack: "feather",
              icon: "icon-alert-circle",
              type: "success"
            });
            // Redirect to home
            this.$router.push({ name: "login" });
          })
          .catch(error => {
            Object.values(error.response.data).forEach(elm => {
              this.$notify({
                title: elm.join(","),
                type: "error"
              });
            });
            // self.$notify({
            //   title: "Error",
            //   text: error.response.data.new_password2[0],
            //   iconPack: "feather",
            //   icon: "icon-alert-circle",
            //   color: "danger"
            // });
          });
      }
    },
    sendConfirm() {
      return AuthService.passwordResetConfirm({
        uid: this.uid,
        token: this.token,
        new_password1: this.new_password1,
        new_password2: this.new_password2
      });
    }
  }
};
</script>
<style scoped></style>
